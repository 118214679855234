export const ARTICLE_TEXT = 'לוטומטיק מאפשרת לכם להצטרף להגרלת הפיס בקליק – מכל מקום ובכל שעה. האם אתם תהיו הזוכים בהגרלת 80 מיליון השקלים בהגרלה הקרובה?\n' +
    'מעת לעת אנו עדים להגרלות יוצאות דופן שגורמות לכל המדינה להאט את הקצב ולחכות למספרי הכדורים המסתובבים בשקיקה. ביום שלישי הקרוב זה הולך לקרות שוב. עם פרס ראשון שעומד על 80 מיליון ₪, אף אחד לא רוצה לפספס את ההזדמנות להיות מיליונר, ולו רק בשביל ליהנות מההרגשה, שיכול להיות שהפעם יתמזל מזלו.\n' +
    'הגרלות הלוטו מבית מפעל הפיס הן בין ההגרלות הפופולריות בישראל, שמאפשרות למשתתפים מכל קשת האוכלוסייה להגדיל את סיכוייהם להיות המיליונרים הבאים של המדינה. \n' +
    'לרגל ההגרלה המיוחדת, לוטומטיק מאפשרת לשלוח טופס מילוי אוטומטי דרך הנייד. השירות המהיר והקל מאפשר לשלוח את טופס ההגרלה במהירות מכל מקום ובכל שעה.\n' +
    'לוטומטיק – שליחת טופס בקליק\n' +
    'לוטומטיק היא מערכת דיגיטלית שמאפשרת לשלוח טופס הגרלה בדרך הנוחה והקלה ביותר. אינכם צריכים להגיע אל דוכני מפעל הפיס, לחכות בתור ולהמתין לשליחת הטופס. עם לוטומטיק אתם יכולים לשלוח בקליק טופס מבלי לצאת מהבית.\n' +
    'דמיינו לכם, שאתם לבושים בפיג\'מה, צופים במהדורת החדשות, מקליקים לשליחת טופס, וכמה שעות לאחר מכן מגלים, שזכיתם בהגרלת הפיס הגדולה שהפרס הראשון בה עומד על 80 מיליון ₪? \n' +
    'אין קל יותר, אין מדהים יותר ואין מטורף יותר. לוטומטיק מספקת את החוויה הכי נוחה ופשוטה למילוי טפסי הגרלה של מפעל הפיס, דרך האתר או דרך האפליקציה.\n' +
    'איך זה קורה? \n' +
    'כך תמלאו את טופס ההגרלה מכל מקום ובכל שעה והגדילו את הסיכויים שלכם לזכות בפרס הראשון שעומד על 80 מיליון ש"ח\n' +
    '1.\tהיכנסו לאפליקציה או לאתר "לוטומטיק".\n' +
    '2.\tהזינו את הפרטים האישיים שלכם. \n' +
    '3.\tבחרו את סוג הטופס שרוצים לשלוח. להגרלת הלוטו הקרובה, שהפרס הראשון שלה עומד על סך 80 מיליון ₪\n' +
    '4.\tשלחו את הטופס.\n' +
    '5.\tמלאו את פרטי כרטיס האשראי במערכת המאובטחת.\n' +
    '6.\tבשלב זה הטופס שמילאתם יישלח אוטומטית אל נקודת מכירה מורשית של מפעל הפיס, שם הוא ייסרק ויעבור ישירות אל תיבת הדואר האלקטרוני ואל האזור האישי שלכם באתר או באפליקציה.\n' +
    '\n' +
    '\n' +
    '\n' +
    'לאן יעברו כספי הזכייה?\n' +
    'זכיתם? מזל טוב!\n' +
    ' הכסף יופקד אוטומטית לחשבון האישי שלכם באתר או באפליקציה\n' +
    'האם הזכייה הקרובה היא שלכם?\n' +
    'ביום שלישי הקרוב תיערך ההגרלה הגדולה. 80 מיליון ₪ עומדים להיות של מישהו או מישהי ברי מזל. האם אתם עתידים להיות המיליונרים הבאים של מפעל הפיס? \n' +
    ' ההשתתפות בהגרלה מותרת רק למי שגילו 18 שנים ומעלה.\n'
export const ARTICLES_TITLE = 'מאמרים'
export const ABOUT_LOTTOMATIC_TITLE = 'אודות'
export const ABOUT_LOTTOMATIC_TEXT = "קצת עלינו..\n" +
    "לוטומטיק פשוט לשלוח בקליק מכל מקום בכל מצב ובכל רגע נתון. \n" +
    "העיקרון המנחה שלנו הינו הנוחות שלכם, כלומר אנו בלוטומטיק נעשה כל שביכולתנו כדי לספק לכם את החוויה למלא טפסי לוטו/ צ׳אנס/ 777/ 123 מבלי להטריח את עצמכם ונעניק לכם את האפשרות לעשות זאת מכל מקום בכל שעה וכל זה בלחיצת כפתור, בלי ללכת לנקודה של מפעל הפיס בלי לעמוד בתור בלי לצאת מהבית ובלי להיות מוגבלים בזמן- הכל נעשה באפליקציה או דרך אתר האינטרנט שלנו. \n" +
    "כל שלכם נותר לעשות הוא פשוט להיכנס לאתר/ לאפליקציה שלנו להזין את הפרטים שלכם לבחור את סוג הטופס שבו אתם חפצים לבחור את המספרים שלכם ו/או לבחור באפשרות של מילוי טופס באופן אוטומטי, ללחוץ על שליחת טופס לשלם בכרטיס האשראי והינה הטופס שמילאתם כבר נסרק ובדרך לתיבת המייל שלכם ולאיזור האישי שלכם באפליקציה. \n" +
    "איך זה עובד? \n" +
    "לאחר התשלום אנו בלוטומטיק מקבלים את הטופס שמילאתם ושולחים אותו עבורכם בנקודת מכירה מורשית של מפעל הפיס, את הטופס ששלחנו עבורכם בנקודה אנו סורקים ומעבירים לכם לתיבת הדואר האלקטרוני ובנוסף מעלים את הטופס הסרוק לאזור האישי שלכם באפליקציה. \n" +
    "הטופס המקורי יישמר אצלנו במשרדי החברה ובמידה וזכיתם בסכום  העולה על 11,000 ש״ח יימסר לכם הטופס באופן אישי. \n" +
    "במידה וסכום הזכייה שלכם הינו מ11,000 ש״ח או נמוך ממנו החשבון שלכם באתר יזוכה באופן אוטומטי ותקבלו על כך הודעה, אתם תוכלו למשוך את הכסף מהחשבון שכלם באתר לתוך חשבון הבנק שלכם או שתבחרו לנצל את היתרה לשליחת טפסים חדשים. \n" +
    "\n" +
    "אנו מאחלים לכם חווית שימוש והנאה מרבית מהשירות שלנו ומלאי תקווה שנביא לכם את המזל. \n" +
    "בהצלחה! \n" +
    "\n\n\n\nאיך זה עובד?\nלאחר התשלום אנו בלוטומטיק מקבלים את הטופס שמילאתם ושולחים אותו עבורכם בנקודת מכירה מורשית של מפעל הפיס, את הטופס שמילאנו עבורכם בנקודה אנו סורקים ושולחים לכם לתיבת הדואר האלקטרוני ומעלים את הטופס הסרוק לאזור האישי שלכם באפליקציה. הטופס המקורי יישמר אצלנו במשרדי החברה ובמידה וזכיתם בסכום העולה על 11,000 ש״ח יימסר לכם הטופס באופן אישי. במידה וסכום הזכייה שלכם הינו מ11,000 ש״ח או נמוך ממנו החשבון שלכם באתר יזוכה באופן אוטומטי ותקבלו על כך הודעה, אתם תוכלו למשוך את הכסף מהחשבון שכלם באתר לתוך חשבון הבנק שלכם או שתבחרו לנצל את היתרה לשליחת טפסים חדשים. אנו מאחלים לכם חוויה שימוש והנאה מרבית מהשירות שלנו ומלאי תקווה שנביא לכם את המזל. בהצלחה!\n\n\n\n"


export const CONTACT_US_TITLE = 'צור קשר'
export const CONTACT_US_TEXT = "מערכות לוטומטיק האתר והאפליקציה זמינות עבורכם לפעילות ושימוש מסביב לשעון, כל יום כל היום- 24 שעות 7 ימים בשבוע.  \n" +
    "\n" +
    "מוקד שירות הלקוחות והתמיכה של לוטומטיק כאן עבורכם וישמח לעמוד לשירותכם לכל שאלה/הבהרה/ תמיכה טכנית או סתם לאחל בהצלחה.\n" +
    "שעות פעילות המוקד : \n" +
    "ימים א׳ עד ה׳ 10:00 עד 23:00 \n" +
    "יום ו׳ 08:30 עד 14:30 \n" +
    "יום שבת כשעה מצאת השבת ועד 23:00 \n"


export const TERMS_OF_USE_TITLE = 'תקנון'
export const TERMS_OF_USE_TEXT = "הוראות תקנון זה יחולו על כל שימוש שיעשה על ידך באתר \"לוטומטיק\" אשר כתובתו http://lottomatic.co.il/ (להלן: \"האתר\"). \n" +
    "האתר משמש כפלטפורמה מקוונת למילוי והגשת טפסי השתתפות בהגרלת הלוטו. אנא קרא את התקנון מפעם לפעם שכן עשויים לחול בו שינויים. אנא שים לב כי השימוש באתר הינו בכפוף להסכמה לתנאי תקנון זה ולשינויים שיחולו בו מעת לעת, ומחובתך להתעדכן בתקנון בכל כניסה לאתר.\n" +
    "השימוש במערכת האתר הינו בכפוף להסכמתך לתקנון ולשינויים שלו.  גלישה באתר ו/או שימוש בו מכל סוג, כמוהם כהסכמה לתנאי התקנון והתחייבות לפעול על פיהם.\n" +
    "מובהר כי התקנון מהווה הסכם משפטי מחייב לכל דבר ועניין ומחייב את המשתמש על כל שימושיו באתר. \n" +
    "תוכן האתר והתקנון מנוסחים בלשון זכר מטעמי נוחות בלבד, ההתייחסות הנה לשני המינים.\n" +
    "הגדרות\n" +
    "למונחים שלהלן תהא המשמעת המפורשת לצידם:\n" +
    "\n" +
    "  \"האתר\"\t\n" +
    "כהגדרתו לעיל, מהווה פלטפורמה מקוונת להשתתפות בהגרלות הלוטו שעורך מפעל הפייס. אתר מאפשר להגיש טפסי השתתפות בהגרלות הלוטו וכן להשתתף בהגרלות של מוצרים ושירותים נוספים.   האתר עובד כחלק נפרד ממפעל הפייס ואינו מהווה שלוחה ו/או יישות משפטית ו/או כל קשר שהוא למפעל הפייס וכן אין לו כל הרשאה ממפעל הפייס לאספקת השירותים שבאתר והאחריות כולה של השירותים המסופקים על ידי האתר הינה באחריות האתר בלבד.  \n" +
    "\n" +
    "\"החברה\"\n" +
    " \tחברת לוטומטיק בע\"מ,ח.פ. 516175841 מרחוב אבן חביב, רמת גן הינה החברה המפעילה את האתר.  \n" +
    "\n" +
    " \"משתתף\"\t\n" +
    "1.\tעל פי החוק, ההשתפות בהגרלות מפעל הפיס מותרת רק אם הינך מעל גיל 18. משתתף הינו אדם אשר גילו מעל 18 ורכש מזכיין כרטיס השתתפות אשר הודפס בנתוני העסקה על ידי מסוף בנקודת מכירה. השימוש באתר יתאפשר רק לאדם העונה על דרישות החוק. \n" +
    "לחץ על הלינק להלן למידע נוסף: https://www.pais.co.il/download/files/Lotto-8306.pdf \n" +
    "\n" +
    "\n" +
    "\n" +
    "1.\tכללי\n" +
    "1.1\tהגלישה והשימוש באתר כפופה להסכמתך לתנאי השימוש. בתקנון השימוש המילה \"שימוש\" על הטיותיה השונות תכלול את הפעולות הבאות: ביקור באתר ו/או גלישה באתר ו/או רישום באתר ו/או כל שימוש אחר באתר. כמו כן, כל שימוש בתקנון השימוש בלשון זכר יכלול גם לשון נקבה ולשון רבים ולהיפך.\n" +
    "1.2\tהתכנים באתר אינם מהוים המלצה לשימוש באתר ו/או להשתתפות בהגרלת הלוטו ו/או כל הגרלה אחרת ובהתאם לכך המשתמש מוותר מראש על כל טענה ו/או תביעה מהחברה ו/או מהאתר בקשר לשימושו באתר ו/או השתתפותו בהגרלת הלוטו ו/או כל הגרלה אחרת ו/או בקשר לתוצאות ההגרלות השונות. \n" +
    "1.3\tאם אינך מסכים באופן מלא לכל התנאים המפורטים בתקנון השימוש, ו/או מצאת כי השימוש באתר ו/או תנאיו ו/או מחיריו אינם עונים לדרישותיך, אנא הפסיק מיד כל שימוש נוסף באתר. השימוש באתר זה הינו על פי תנאי תקנון זה בלבד. ככל והמשתמש בחר לעשות שימוש באתר לא תהיינה לו כל טענה ו/או דרישה ו/או תביעה מהאתר ו/או החברה.\n" +
    "1.4\tהמשתמש מודע ומסכים כי השימוש באתר ובשירותיו הינם בכפוף לתקנון זה ולדין החל עליו. השימוש באתר מהווה הסכמה וראייה כי המשתמש פועל בהתאם לכל לדין ולכל תקנה ו/או חוק ישראלי ו/או בינלאומי, החל על האתר ו/או על שירותיו ו/או על המשתמש. \n" +
    "1.5\tלשיקול דעתו של האתר באם לסרב ו/או לחסום משתמש/ים למתן שירותים באתר. האתר יהיה רשאי לחסום את שירותיו למשתמש ו/או להפסיק מתן שירות. יודגש כי רק משתמש כהגדרתו בתקנון זה רשאי לעשות שימוש באתר. אדם אשר אינו עונה על ההגדרה של משתמש ובכל זאת עושה שימוש באתר, לאתר ו/או לחברה לא תהא כל חבות ו/או אחריות בגין השימוש הנ\"ל. \n" +
    "1.6\tמשתמש אשר יעשה בשירותי האתר בניגוד להוראות תקנון זה ויגרום להוצאה ו/או לנזק (ישיר ו/או עקיף) ו/או להפסד ו/או לאובדן לאתר ו/או לחברה ו/אומי מטעמה ו/או למשתמשי האתר, ישפה את האתר ו/או את החברה בגין כל הוצאה שתהא וכל נזק (לרבות נזק ישיר ועקיף), ויהיה אחראי לכל דרישה ו/או תביעה אשר תוגש כנגד האתר בגין כך. \n" +
    "1.7\tכמו כן, משתמש שיעשה שימוש בניגוד לתקנון האתר אשר יגרום לפגיעה במוניטין האתר ו/או בשמו הטוב של האתר, ישפה את האתר ו/או את החברה בגין כל הוצאה ו/או הפסד שיגרם בעקבות כך.\n" +
    "1.8\tהחברה מסירה מעצמה אחריות על פעילויות הגולשים באתר שנעשו באופן בלתי חוקי ו/או במטרה לגרום לנזקים לאתר או לגולשים האחרים בו.\n" +
    "1.9\tהשימוש באשר  מהווה הסכמה לתקנון זה והמשתמש נותן הסכמתו בזאת לאתר לשלוח לו קבלה ו/או חשבונית בדואר אלקטרוני וכן מידע שיווקי על לוטומטיק ומוצריה.\n" +
    "1.10\tידוע ומוסכם על משתמש כי ייתכן והמידע שלו יימסר לרשויות המס, לפי דרישת הרשות. לחץ על הלינק להלן למידע על מדיניות הפרטיות של האתר:  [לינק]\n" +
    "\n" +
    "2.\tהליך הרישום\n" +
    "2.1\tקבלת השירותים באתר מותנים בהליך רישום לאתר על ידי מילוי טופס רישום (להלן: \"טופס הרישום\"). הרישום יהא כפוף למגבלות המפורטות בתקנון זה. טופס הרישום ימולא על ידך תוך ציון פרטים מלאים ומהימנים וכן תידרש להגדיר סיסמא אישית. המשתמש מאשר בזאת כי החברה ו/או האתר מאפשר את השימוש בו על סמך הפרטים הממולאים בטופס הרישום.\n" +
    "2.2\tהאתר ישמור את פרטיך ואת טופס הרישום, בהתאם למדיניות הפרטיות של האתר. פרטי המשתמש נשמרים באתר החברה שהינו אתר מאובטח. לא יתאפשר שימוש של כל גורם אחר מלבד האתר במידע אשר נמסר ע\"י המשתמש, אלא במקרה שהמשתמש עצמו ביקש או אישר זאת מפורשות.\n" +
    "2.3\tלאחר סיום מילוי טופס הרישום ואישור תנאי התקנון שבאתר, תהיה רשאי להתחיל לפעול באתר ולהשתמש בשירותיו.\n" +
    "2.4\tהרישום והשימוש באתר הינם אישיים וחל איסור מוחלט על כל אדם לרשום אדם אחר לאתר ללא הסכמתו המפורשת, ואין למסור כל פרטים בעת הרישום לאתר ו/או בכל הליך אחר, אלא את פרטיך האיישים בלבד. שימוש במילוי הטופס בניגוד לאמור יהווה הפרה של הוראות תקנון האתר ולאתר שמורה הזכות לבטל את הרישום ו/או לחסום את המשתמש ו/או להפסיק את שירותו. החברה ו/או האתר יהיו זכאים לכל הסעדין בדין במקרה שכזה. \n" +
    "2.5\tפרטי המשתמש שימולאו בטופס ישמשו את האתר לאימות זהותו של המשתמש מעת לעת, לרבות בעת מסירת טופס זוכה למשתמש. בהתאם לאמור, ככל ואחד מהפרטים ו/או כולם אשר נמסרו לא יהיו זהים לפרטי המשתמש, כפי שמולאו בעת הרישום ו/או כפי שהינם מופיעים ברישומי האתר, תהא החברה ו/או האתר רשאים לסרב למסור למשתמש טופס זוכה או כל מידע אחר.\n" +
    "2.6\tלצורך אימות פרטי זיהוי, יהיה האתר ו/או החברה, רשאין לדרוש ממך צילום תעודת זהות. \n" +
    "2.7\t עליך להיות בעל תיבת דואר אלקטרוני פעילה, לטובת שליחה וקבלה של הודעות דואר אלקטרוני מאת האתר ו/או החברה.\n" +
    "2.8\tעליך להיות בעל חשבון בנק פעיל בישראל ו/או בעל כרטיס אשראי הניתן לסליקה. \n" +
    "2.9\tמובהר כי על המשתמש יחולו כל ההגבלות שבדין לעניין השימוש באתר בגבולות מדינת ישראל.\n" +
    "\n" +
    "3.\tפתיחת חשבון משתמש וניהולו\n" +
    "3.1\tעם השלמת הליך הרישום לאתר ולצורך ביצוע פעולות באתר ושליחת טופס/ים, נדרש המשתמש כדקלמן:\n" +
    "3.1.1\tהסכמה לחיוב כרטיס האשראי שלך כתנאי למשלוח טפסים. החיוב יהיה בסכום שמולא על ידך בטופס ו/או הפקדה של הסכום שממולא בטופס לחשבון המשתמש באתר וזאת באמצעות חיוב כרטיס אשראי בלבד. יתרת זכות שאינה מנוצלת, תשמש לצורך משלוח טפסים עתידיים ו/או פעולות אחרות באתר.\n" +
    "3.1.2\tעם אישור חיוב כרטיס האשראי שלך תשלח אליך הודעת דוא\"ל בגין החיוב.\n" +
    "3.1.3\tטפסים זוכים יזוכו בחשבונך שבאתר ותוכל למשוך אותם מחשבונך באתר בכל עת לכרטיס האשראי שלך שפרטיו נמסרו על ידך במילוי הטופס. הזיכוי יהיה בכפוף למגבלות הקבועות בחוק ו/או מגבלות של חברת האשראי. ייתכן והמשיכה תהא כרוכה בתשלום עמלה, שיכול ותשתנה מפעם לפעם.\n" +
    "3.1.4\tהינך מסכים בזאת כי לשיקול דעתו הבלעדי של האתר ובכפוף לדרישות החוק, יהיה רשאי האתר להגביל את הסכום שיופקד על ידך לשליחת הטפסים, במועד אחד או לאורך תקופת זמן, כמו כן להגביל את גם להפקדה לחשבונך באתר. הינך מסכים בזאת לאמור בסעיף זה. \n" +
    "3.1.5\tהינך מסכים בזאת למסור לאתר את פרטי חשבון הבנק שלך, לטובת ביצוע העברות כספים מחשבונך באתר (מבלי לגרוע מהאפשרות לבצע זיכוי לכרטיס האשראי שלך). האתר על פי שיקול דעתו יבחר אם לבצע העברה לחשבונך, במקום זיכוי כרטיס האשראי. \n" +
    "3.1.6\tההעברות לחשבונך ו/או זיכוי כרטיס האשראי הינם בכפוף למגבלות הקבועות בדין וכן להוראות חוק איסור הלבנת הון, תש\"ס-2000.\n" +
    "\n" +
    "4.\tאופן שליחת הטפסים\n" +
    "4.1\tראשית עליך לבחור באתר את טופס ההגרלה שבה תרצה להשתתף ולסמן את המספרים ו/או הסיומנים במקום המיועד להם בטופס.   לאחר סיום מילוי הטופס תתבקש ללחוץ על הכפתור \"שלח\" שבתחתית הטופס. \n" +
    "4.2\tבשלב זה האתר יעלה עמוד לאישור השליחה, שם יפורט הסכום הכולל של העסקה בצירוף העמלה לאתר (הסכום אותו יחייב האתר את כרטיס האשראי שלך או את חשבון המשתמש שלך). \n" +
    "4.3\tיובהר כי שליחת הטופס מותנית בכך כי בחשבונך יתרת זכות מספקת לחיוב וכן באישורך לחיוב כרטיס האשראי. \n" +
    "4.4\tמובהר כי אישור שליחת הטופס כמוהו כאישור מפורש להרשה בלתי חוזרת למשלוח הטופס ע\"י האתר וחיובך בסכום הנקוב בו ובתשלום לאתר. עם אישורך יועבר הטופס לאחת מתחנות מפעל הפיס למשלוח.\n" +
    "4.5\tהקבלה המאשרת את משלוח הטופס תישלח אליך בדוא\"ל לאחר משלוח הטופס באתר ובכפוף לאילוצים טכניים ותקלות, אשר בגינן לא תהיה למשתמש כל טענה ו/או תביעה. הקבלה מעידה כי הטופס נשלח בהצלחה ועל המסך יופיע קישור המאפשר גישה למשתמש לגשת ולראות את הטופס שנשלח באתר.  בהודעה יופיע לינק לקישור למשתמש לראות את הטופס שנשלח בשחבונו באתר.  הינך מאשר בזאת כי מטעמי אבטחה ושמירה על סודיות, האתר יהא רשאי לשלוח את העתק הטופס הסרוק באופן חלקי. \n" +
    "4.6\tהינך מאשר בזאת כי האתר ו/או החברה יהיו רשאים לשלוח את הטופס באמצעות מי מטעמה ו/או שלוחיה, ובמקרה כזה על גבי הטופס יופיע שמו של אותו שלוח ששלח את הטופס מטעמה. על אף כי במקרה כזה תופיע תעדות הזהות של אותו שלוח מטעם האתר ו/או החברה, הרי כי טופס שייך לך ונשלח עבורך ובמקרה של טופס זוכה, אותו שלוח יגבה את התשלום בעבור הזכייה וכספים אלו יועברו ישירות אליך באמצעות האתר ו/או החברה. \n" +
    "4.7\tמובהר כי בהגרלות שסכום הזכיה בהן עולה על הסכום הקבוע בדרישות משרד האוצר ועפ\"י תקנון מפעל הפיס, יופיעו גם פרטי ארבעת הספרות האחרונות של מספר הזהות של המשתמש באופן מקודד ומוצפן. \n" +
    "4.8\tיובהר כי משלוח הטופס על ידך באמצעות האתר ו/או בהעברתו למשלוח במסוף, לא יהווה שום התחייבות של האתר, כי הטופס תקין ו/או כי אכן נשלח למפעל ורק השלמת התהליך לקבלת העתק סרוק של הקבלה שהופקה במסוף במערכת יחשב כהשלמת התהליך והגשה תקינה של הטופס.\n" +
    "4.9\tשליחת טופס באמצעות האתר כפוף לכך כי ישנה תחנת מפעל פיס פעילה וזמינה; כי טופס ההשתתפות יתקבל רק זמן מספק בטרם מסתימת ההגרלה. כמו כן לחברה ו/או לאתר שיקול הדעת הבלעדי בעניין שליחת טפסי המשתמש, לרבות במקרה עומסים, ליקויי תקשורת, מגבלות חוקיות ו/או כל סיבה אחרת לשיקול דעת החברה ו/או האתר. \n" +
    "4.10\tמשלוח טופס באתר שלא הסתיים בקבלת העתק סרוק של הקבלה בשל אי אילו סיבות, לרבות אלו המצויינות לעיל, לא ישתתף בהגרלה הקרובה ולמשתמש לא תהיה כל עילה להגשת תביעה כלשהי כנגד האתר ו/או החברה ו/או מי מטעמה. הינך מוותר מראש, על כל דרישה ו/או תביעה כנגד האתר ו/או החברה בקשר לכך. במידה וחויב המשתמש בעלות משלוח הטופס, והפעולה לא הסתיימה בשליחת הטופס יזוכה המשתמש במלוא סכום החיוב. \n" +
    "\n" +
    "5.\tשליחת טפסים אוטומטית על ידי מערכת האתר (להלן: \"מנוי\")\n" +
    "5.1\tהאתר מציע למשתמש את שירותיו גם במערכת שליחת טפסים אוטומטית. כדי להשתתף במערכת המילוי האוטומטית עליך לרכוש מנוי דרך האתר על ידי סימון האופציה \"מנוי\" בתוך האתר. כחלק משירותי האתר יתאפשר למשתמש למלא ולשלוח טפסי הגרלה באופן אוטומטי ומערכת האתר תבקש מהמשתמש לרכוש מנוי ולסמן אופציה זו באתר. עם סימונך את לחצן ה\"מינוי\" ולאחר סימון המספרים בטפסי ההגרלה, ישלח האתר את הטפסים באופן אוטומטי.  \n" +
    "5.2\tתשומת ליבך כי סימון \"המנוי\" באתר מהווה הסכמתך לחיוב כרטיס האשראי שלך בכל עת בו ישלח טופס הגרלה ע\"י האתר באמצעות המנוי. \n" +
    "5.3\tבכדי לבטל את המנוי עליך לבטל את הסימון באתר. \n" +
    "5.4\tהוראות התקנון חלות על המנוי כשם שהן חלות על משתמש באתר. \n" +
    "\n" +
    "6.\tחיוב בגין שירות משלוח טפסי ההגרלה\n" +
    "6.1\tתעריף החיוב בגין שירות משלוח הטופס באתר יכול שיכלול עמלת שירות. האתר שומר על זכותו לשנות את שיעור העמלה ולשנותה מעת לעת לשיקול דעתו בלבד. אשר יחול על המשתמש ישתנה בהתאם לעלות הטופס. הנהלת האתר רשאית לשנות את עמלת שליחת הטופס, מפעם לפעם. תעריף החיוב והעמלה יוצג בדף אישור העסקה קודם למשלוח הטופס. האתר שומר לעצמו את הזכות לגבות עמלה בגין שירותים המוצעים בו. האתר יהיה רשאי לשנות את שיעור העמלה (בגין שליחת טפסים עתידיים) או לוותר על העמלה, לפי שיקול דעתו הבלעדי.\n" +
    "6.2\tלמען הסר ספק, העמלה הינה חלק מעלות העסקה הכוללת. בעת בו יחוייב חשבונך או כרטיס האשראי שלך, תחוייב בעסקה הכוללת בגין עלות משלוח טופס ההגרלה וכן בתוספת העמלה. למשל: אם טופס ההגרלה מחירו 5.50 ₪ ועלות העמלה הינה 4 ₪, עלות העסקה הכוללת תהיה 9.5₪. \n" +
    "6.3\tמוסכם בזאת כי העמלה אינה כוללת הוצאות שאינן משלוח אלקטרוני. למען הסר ספק העמלה אינה כוללת שירותי משלוחים ו/או עמלות בגין העברה ו/או הפקדה ו/או זיכוי האשראי של המשתמש, בהן יחוייב האתר.\n" +
    "\n" +
    "7.\tזכייה בטפסי הגרלה זוכים ואופן הזיכוי \n" +
    "7.1\tלמעט זכיות העולות על סך של 11,000₪ בהן סכום הזכייה מועבר ישירות אל המתשמש הזוכה, כל זכיה מתחת לסכום האמור נותן המשתמש הסכמתו הבלתי חוזרת לגבות עבורו את כספי הזכייה בהגרלות מפעל הפיס בהן ישתתף. לטובת האמור, המשתתף מבין ומאשר בזאת כי האתר ו/או החברה ו/או מי מטעמה יהיו שלוחיו של המשתמש לשם החזקת הטופס הזוכה, מסירתו לתחנת מפעל הפיס, קבלת סכום הזכייה והעברתו אל המשתמש. כספי הזכיה יועברו אל המשתמש או באמצעות זיכוי החשבון באתר או זיכוי כרטיס האשראי (אלא אם ישנה מגבלה כלשהי ו/או כי הזיכוי אינו מתאפשר בשל תנאי מפעל הפיס.)  עמלת העברת התשלום לחשבונך ו/או עמלת זיכוי כרטיס האשראי שלך, ככל ותתווסף, תשולם על ידך ובשיעור שיקבע האתר מעת לעת. \n" +
    "7.2\tאם קיימת למשתמש יתרת זכות בחשבונו באתר, יוכל לעשות בה שימוש להגרלות נוספות. כמו כן יוכל לבקש להעביר את יתרת הזכות לחשבון כרטיס האשראי שלו ו/או לחשבון הבנק (בהתאם לפרטים אשר נמסרו בהרשמה לאתר). \n" +
    "7.3\tלמען הסר ספק מובהר ומוסכם כי זיכוי טופס זוכה יבוצע אך ורק לחשבון בנק של המשתמש  ו/או כרטיס אשראי על שם המשתמש בלבד.\n" +
    "7.4\tזיכוי חשובנך באתר בגין טפסי זכייה, יתבצע סמוך ככל האפשר למועד קבלת הכספים בפועל ממפעל הפיס.\n" +
    "7.5\tטפסים שלא ניתן לזכות באתר היות וסכום הזכיה עולה על הסכום הקבוע בתקנון זה  ו/או על תקרת הסכום הפטור ממס ו/או בשל תנאי אחר של מפעל הפיס, יזומן המשתתף למשרדי החברה, שם ימסר לו טופס הזכייה כנגד הצגת תעודת זהות הכוללת ספח, לשיקול דעת החברה לבקש כל תעודה או אישור זיהוי נוסף אשר יאפשר זיהוי מוחלט וודאי של המשתמש. תשומת ליבך כי במקרה כזה ייתכן ותידרש לפנות בעצמך למשרדי מפעל הפיס בכדי לקבל לידך את סכום הזכייה.  \n" +
    "\n" +
    "8.\tתמיכה ושירות \n" +
    "8.1\tהאתר עושה כל שביכולתו ליתן למשתמשיו תמיכה מלאה בכל שעות היממה, ואולם המשתמש מצהיר ומסכים בזאת כי לא תהא לו כל טענה ו/או דרישה ו/או תביעה כלפי האתר בגין שירות האתר ו/או זמני התגובה באתר ו/או כל עניין אחר הקשור בתמיכת האתר והו מוותר מראש על כל טענה ו/או תביעה בגין  האמור. \n" +
    "8.2\tהאתר יתן מענה לקריאת השירות שתיפתח על ידי המשתמש באתר תוך __ ימי עסקים ממועד פתיחתה. המשתמש יקבל הודעה בגין סטטוס הטיפול בקריאה.\n" +
    "\n" +
    "9.\tביטולים\n" +
    "מובהר ומוסכם בזאת כי לאחר אישור משלוח טופס הגרלה לא תינתן אפשרות לביטול שליחת הטופס. \n" +
    "\n" +
    "10.\tהגבלת אחריות\n" +
    "10.1\tהמשתמש מאשר בזאת כי האחריות כולה לשימושיו השוניםבאתר חלה עליו בלבד ולאתר ו/או החברה לא יהיו אחראיים כלפי המשתמש ו/או צדדים שלישיים לכל פעולה ו/או נזק ו/או הפסד בעקבות השימוש באתר.\n" +
    "10.2\tהאתר ו/ו החברה לא יהיה אחראיים לנכונות ו/או לאמיתות כל מידע או תוכן שצוין ע\"י המשתמש בעת ההרשמה לאתר ו/או בעת מילוי טופס הרישום. \n" +
    "10.3\tמבלי לגרוע באמור לעיל , מובהר בזאת כי לאתר ו/או לחברה אין כל חבות או אחריות בגין הזנת פרטים שאינם מדויקים ע\"י המשתמש.\n" +
    "10.4\tהמשתמש נותן התחייבותו בזאת כי לא יעשה כל שימוש מנוגד לדין בעצם שימושו באתר.  חל איסור על שימוש באתר ע\"י משתמש שגילו מתחת ל-18 ו/או מפאת מגבלה אחרת. פעולות שיעשה המשתמש באתר שהינן מנוגדות לדין, לרבות השתתפות שאינה חוקית מפאת הגיל, תהא באחריות המשתמש בלבד והוא פוטר בזאת את האתר ו/או את החברה מכל אחריות ומתחייב לשפות את האתר בגין כל תביעה ו/או דרישה הנובעת בגין כך.\n" +
    "10.5\tהאתר עושה כל מאמץ לשמור על תקינות פעילותו. חרף האמור, האתר אינו מתחייב כי השירות באתר לא יופרע, ויתכן ויחולו תקלות– לרבות תקלות בחומרה, בתוכנה או בקווי התקשורת, והאתר לא יהיה אחראי באופן כלשהו לתקלות ו/או שיבושים שכאלו ולמשתמש לא תהיה כל טענה ו/או תביעה בהקשר זה. המשתמש מסכים כי האתר ו/או החברה יהיו פטורים מכל אחריות לכל נזק, הפסד ו/או הוצאה, מכל סוג שהוא, שיגרמו לו או לכל צד ג' במישרין או בעקיפין כתוצאה מהאמור לעיל.\n" +
    "10.6\tלאתר שיקול דעת בלעדי לביטול/השעיה רישום משתמש באתר, בקרות אחד או יותר מהבאים: \n" +
    "10.6.1\tמשתמש שעשה שימוש באתר בניגוד לתקנון זה ו/או בניגוד לדין. \n" +
    "10.6.2\tמשתמש שביצע /או ניסה לבצע, בין במעשה ו/או מחדל, פעולה שיש בה כדי לפגוע באתר ו/או במשתמש אחר ו/או בחברה ו/או בצדדים שלישיים.\n" +
    "10.6.3\tמשתמש אשר הפר את חובתו לאיסור שימוש בקניין הרוחני של האתר. \n" +
    "10.6.4\tמשתמש אשר במעשה או מחדל הוביל לפגיעה בשמו הטוב של האתר ו/או מפעיליו ו/או החברה. \n" +
    "10.7\tהמשתמש מסכים כי האתר ו/או החברה יהיו פטורים מכל חבות ו/או אחריות הנובעת מהשימוש באתר ולמשתמש לא תהיה כל טענה ו/או תביעה כנגד האתר ו/או החברה בקשר לתוצאות שימושו בשירותי האתר. \n" +
    "\n" +
    "11.\tשמירת טפסי ההשתתפות בהגרלות\n" +
    "11.1\tטופס שנשלח ולא זכה בכל פרס שהוא, יושמד בחלוף 7 ימים ממועד ההגרלה. המשתמש יהיה רשאי לפנות בבקשה לאתר בתוך המועד הנ\"ל ולבקש לקבל לידיו את הטופס המקורי. \n" +
    "11.2\tטופס שזכה בפרס כלשהו בהגרלה, וזוכה כרטיס האשראי ו/או חשבון הבנק ו/או חשבון המשתמש באתר של המשתמש, יושמד בחלוף 14 ימים ממועד קבלת ההודעה בגין הזכייה. המשתמש יהיה רשאי לפנות בבקשה לאתר בתוך המועד הנ\"ל ולבקש לקבל לידיו את הטופס המקורי.\n" +
    "11.3\tטופס אשר זכה בפרס כלשהו ולא זוכה באתר בשל תנאי מפעל הפיס (לדוגמא במקרה של סכום זכייה גבוה), יישמר באתר לתקופה של כ-6 חודשים ממועד קבלת ההודעה על זכייה. \n" +
    "11.4\tבחלוף המועדים לעיל, ככל והמשתמש לא דרש את הטופס המקורי, רשאי האתר ו/או החברה להשמיד את הטופס המקורי לשיקול דעתם הבעלדי והמשתמש מוותר על כל תביעה ו/או דרישה מהאתר ו/או מהחברה ו/או מי מטעמם.    \n" +
    "\n" +
    "12.\tקניין רוחני\n" +
    "זכויות הקניין הרוחני באתר, לרבות המערכת, שם האתר, לוגו האתר, לוגו החברה, הנתונים באתר, קוד התוכנה וכיו\"ב הינן של מפעילי האתר בלבד ו/או של החברה. המתשמש מתחייב בזאת שלא להעתיקם ו/או לשכפלם ו/או ליישם אותם בכל צורה שהיא בין במישרין ובין בעקיפין. \n" +
    "\n" +
    "13.\tמדיניות פרטיות \n" +
    "למדיניות הפרטיות של האתר יש ללחות על הלינק הבא: ______ \n" +
    "\n" +
    "14.\tהתיישנות  \n" +
    "14.1\tהמשתמש מסכים בזאת כי על השימוש שיעשה באתר יחולו הוראות סעיף 19 לחוק התיישנות, התשי\"ח-1958 (להלן: \"החוק\"), וייחשבו כהסכם נפרד.  \n" +
    "14.2\tתקופת ההתיישנות ביחס לכל שימוש שעשה המשתמש באתר ו/או בשירותיו וכן בגין כל תביעה שהיא, שעניינה שימוש באתר ו/או בהקשר לתקנון זה, הינה 6 חודשים ממועד האירוע נשוא התביעה. \n" +
    "14.3\tמובהר ומוסכם בזאת כי שימוש המשתמש באתר מהווה הסכמת המשתמש להסכם נפרד זה לעניין תחולת סעיף 19 לחוק וקיצור תקופת ההתיישנות לשישה חודשים כאמור.    \n" +
    "\n" +
    "15.\tסמכות שיפוט\n" +
    "15.1\tעל תקנון זה ועל כל הנובע ממנו ו/או הכרוך בו יחולו דיני מדינת ישראל בלבד. \n" +
    "15.2\tכל סכסוך ו/או חילוקי דעות בין הצדדים, תהא נתונה לסמכות השיפוט הבלעדית בבתי המשפט בתל-אביב, ולהם בלבד.\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "16.\tשונות\n" +
    "16.1\tכל שימוש המשתמש באתר הינו על אחריותו בלבד וכל פעולה שהאתר יבצע בעקבות השימוש תחייב את המשתמש. הרישומים באתר באשר לכל השימושים בו על ידי המשתמש והפעולות שביצע יהוו ראיה חלוטה לנכונות האמור בהם.\n" +
    "16.2\tהודעה שנשלחה למשתמש תיחשב כאילו הגיעה ליעדה 24 שעות לאחר שליחתה במערכת ההודעות במערכת או בדואר האלקטרוני, או 3 ימי עסקים לאחר שליחתה בדואר רשום.\n"
